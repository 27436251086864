import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Section from "./section"
import Pattern from "./pattern"
import Img from "gatsby-image"
import ModalVideo from 'react-modal-video'

import '../../node_modules/react-modal-video/scss/modal-video.scss'

const featureSectionHeader = (text) => (
  <div className="text-center text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl my-16 md:my-20 text-indigo-600">
    {text}
  </div>
)


const PlayButton = () => <svg height="160" className="absolute z-50 opacity-75 cursor-pointer" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 122.88">
  <g fillRule="evenodd" clipRule="evenodd">
    <path fill="#eee" d="M61.44,0c33.93,0,61.44,27.51,61.44,61.44s-27.51,61.44-61.44,61.44S0,95.37,0,61.44S27.51,0,61.44,0L61.44,0z M83.31,65.24c3.13-2.02,3.12-4.27,0-6.06L50.98,40.6c-2.55-1.6-5.21-0.66-5.14,2.67l0.1,37.55c0.22,3.61,2.28,4.6,5.32,2.93 L83.31,65.24L83.31,65.24z M61.44,12.48c27.04,0,48.96,21.92,48.96,48.96c0,27.04-21.92,48.96-48.96,48.96S12.48,88.48,12.48,61.44 C12.48,34.4,34.4,12.48,61.44,12.48L61.44,12.48z"/>
  </g>
</svg>

const FeatureRight = (props) => {
  const [isOpen, setOpen] = useState(false)

  return <div className="relative mt-12 sm:mt-16 sm:mt-24" id={props.id}>
    {props.youtubeID && <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.youtubeID} onClose={() => setOpen(false)} />}

    <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-8 sm:items-center">
      <div className="sm:col-start-2">
        <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
          {props.title}
        </h4>

        <p className="mt-4 text-lg leading-7 text-gray-500">
          <span className="mt-6 font-medium block">{props.description[0]}</span>
          <span className="mt-4 font-light block">{props.description[1]}</span>
        </p>

        {props.youtubeID && (<div>
          <a
            href="/"
            id={`features-${props.id}-watch-movie-link`}
            className="mt-2 inline-flex items-center justify-center py-1 border border-transparent text-base leading-6 font-medium rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out text-blue-800 hover:text-blue-600 hover:underline"
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
            }
            }
          >
            Watch movie &raquo;
          </a>
        </div>)}
        {/*<ul className="mt-10">
          <li>
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Mobile notifications
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10">
            <div className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4">
                <h5 className="text-lg leading-6 font-medium text-gray-900">
                  Reminder emails
                </h5>
                <p className="mt-2 text-base leading-6 text-gray-500">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </p>
              </div>
            </div>
          </li>
        </ul>*/}
      </div>

      <div className="mt-10 relative sm:mt-0 sm:col-start-1 w-full flex flex-row justify-center">
        <svg
          className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 sm:hidden"
          width="784"
          height="404"
          fill="none"
          viewBox="0 0 784 404"
        >
          <defs>
            <pattern
              id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="784"
            height="404"
            fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
          />
        </svg>
        <div className="w-full flex flex-col justify-start">
          <div className="hidden sm:block">
            <Pattern />
          </div>
          <div
            id={`features-${props.id}-watch-movie-img`}
            className="w-full flex flex-row justify-center items-center"
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
            }}
          >
            {props.youtubeID && <PlayButton />}
            <Img fixed={props.image.fixed} fluid={props.image.fluid} className="mx-auto shadow-xl hover:shadow-2xl rounded-lg cursor-pointer w-full object-cover" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
}

const FeatureLeft = (props) => {
  const [isOpen, setOpen] = useState(false)

  return <div className="relative mt-12 sm:mt-24 sm:grid sm:grid-cols-2 sm:gap-8 sm:items-center" id={props.id}>
    {props.youtubeID && <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.youtubeID} onClose={() => setOpen(false)} />}
    <div className="relative">
      <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
        {props.title}
      </h4>
      <div className="mt-4 text-lg leading-7 text-gray-500">
        <span className="mt-6 text-xl font-medium block">
          {props.description[0]}
        </span>
        <span className="mt-4 font-light block">{props.description[1]}</span>

        {props.youtubeID && (<div>
          <a
            href="/"
            className="mt-2 inline-flex items-center justify-center py-1 border border-transparent text-base leading-6 font-medium rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out text-blue-800 hover:text-blue-600 hover:underline"
            id={`features-${props.id}-watch-movie-link`}
            onClick={(e) => {
              e.preventDefault()
              setOpen(true)
            }
            }
          >
            Watch movie &raquo;
          </a>
        </div>)}
      </div>

      {/*
    <ul className="mt-10">
      <li>
        <div className="flex">
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
              </svg>
            </div>
          </div>
          <div className="ml-4">
            <h5 className="text-lg leading-6 font-medium text-gray-900">
              Competitive exchange rates
            </h5>
            <p className="mt-2 text-base leading-6 text-gray-500">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Maiores impedit perferendis suscipit eaque, iste dolor
              cupiditate blanditiis ratione.
            </p>
          </div>
        </div>
      </li>
      <li className="mt-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                />
              </svg>
            </div>
          </div>
          <div className="ml-4">
            <h5 className="text-lg leading-6 font-medium text-gray-900">
              No hidden fees
            </h5>
            <p className="mt-2 text-base leading-6 text-gray-500">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Maiores impedit perferendis suscipit eaque, iste dolor
              cupiditate blanditiis ratione.
            </p>
          </div>
        </div>
      </li>
      <li className="mt-10">
        <div className="flex">
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>
          </div>
          <div className="ml-4">
            <h5 className="text-lg leading-6 font-medium text-gray-900">
              Transfers are instant
            </h5>
            <p className="mt-2 text-base leading-6 text-gray-500">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Maiores impedit perferendis suscipit eaque, iste dolor
              cupiditate blanditiis ratione.
            </p>
          </div>
        </div>
      </li>
    </ul> */}
    </div>

    <div className="mt-10 sm:mt-0 w-full flex flex-col justify-start">
      <div className="hidden sm:block">
        <Pattern />
      </div>
      <div
        id={`features-${props.id}-watch-movie-img`}
        className="w-full flex flex-row justify-center items-center"
        onClick={(e) => {
          e.preventDefault()
          setOpen(true)
        }}
      >
        {props.youtubeID && <PlayButton />}
        <Img fixed={props.image.fixed} fluid={props.image.fluid} className="mx-auto shadow-xl hover:shadow-2xl rounded-lg cursor-pointer w-full object-cover" alt="" />
      </div>
    </div>
  </div>
}

/*
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
*/

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg|png)/i" }
          relativeDirectory: { eq: "images" }
        }
      ) {
        edges {
          node {
            id
            name
            relativeDirectory
            extension
            
            childImageSharp {
              fixed(width: 500, quality: 100) {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `)

  const imageWithName = (name) => {
    const edgesFound = data.allFile.edges.filter(
      edge => edge.node.name === name
    )

    if (edgesFound.length > 0) {
      if (edgesFound[0]) {
        const edge = edgesFound[0]
        const { node } = edge
        if (node && node.childImageSharp) {
          return { fixed: node.childImageSharp.fixed, fluid: node.childImageSharp.fluid }
        }
      }
    }
  }

  return (
    <Section>
      <div className="py-16 bg-gray-50 overflow-hidden sm:py-24" id="features">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 sm:px-8 sm:max-w-screen-xl">
          <svg
            className="hidden sm:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width="404"
            height="784"
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="784"
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Features
            </h3>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            Meet Cam overlays visual widgets on top of your camera image,<br className="hidden sm:block" />
            improving video meetings for everyone!
            </p>
          </div> 

          
          {featureSectionHeader('Available Now 🥳')}

          <FeatureLeft
            id="meeting-timer"
            title="Meeting Timer"
            youtubeID="ezgFFzmksDk"
            description={[
              "Shows how much time is left in the meeting for everyone to see at a glance.",
              "Keeps discussion in a timeframe without you hurrying others up.",
            ]}
            image={imageWithName("meetcam-meeting-timer")}
          />

          <FeatureRight
            id="countdown-timer"
            title="Countdown Timer"
            youtubeID="o2oS5KiMDOo"
            description={[
              "Displays a countdown timer to timebox parts of your call.",
              "Helps people stick to the point and communicate efficiently in time you allocated them, without you having to interrupt.",
            ]}
            image={imageWithName("meetcam-countdown-timer")}
          />

          <FeatureLeft
            id="headline"
            title="Headline"
            youtubeID="PQcP0D5wFrg"
            description={[
              "Displays one important line of text on the bottom of your video.",
              "Make sure no one misses the point you're making, directions you're giving, or subject you want to talk about.",
            ]}
            image={imageWithName("meetcam-headline")}
          />

<FeatureRight
            id="emojis"
            title="Emojis"
            youtubeID="UFsVCnnE5mc"
            description={[
              "Brings large, animated emoji front and center.",
              "Allows you to express yourself and react visually, without interrupting. Very helpful when you're just one of the small boxes on the call.",
            ]}
            image={imageWithName("meetcam-emojis")}
          />

          {/* <FeatureRight
            id="snow"
            title="Snow"
            description={[
              "Not snowing outside?",
              "A selection of snow options will definitely help when meeting with your friends.",
            ]}
            image={imageWithName("meetcam-snow")}
          />
 */}
          {featureSectionHeader('Coming Soon! 👨‍💻')}

          <FeatureLeft
            id="gifs"
            title="GIFs"
            youtubeID="v0U178dYWy0"
            description={[
              "Displays an animated GIF of your choosing.",
              "If emojis aren't enough, react with GIFs! BTW I bet that GIF is funnier than the other guy's joke!",
            ]}
            image={imageWithName("meetcam-gifs")}
          />

          <FeatureRight
            id="agenda"
            title="Agenda"
            youtubeID="co_SGA_dM_s"
            description={[
              "Displays list of agenda items - both discussed, ongoing and upcoming.",
              "Helps to impose structure on the meeting by showing its progress and preventing anyone going off on a tangent.",
            ]}
            image={imageWithName("meetcam-agenda")}
          />

          <FeatureLeft
            id="card-stack"
            title="Card Stack"
            youtubeID="t3aDHo5d0YY"
            description={[
              "Displays a set of post it notes with your text.",
              "Ever wished you were in the same room just to use Post It notes? It's as close as it gets. Try it out on your next Retrospective or Planning Poker!",
            ]}
            image={imageWithName("meetcam-card-stack")}
          />

          <FeatureRight
            id="bullet-points"
            title="Bullet Points"
            youtubeID="j-Z8WMD8fsk"
            description={[
              "Reveal bullet points in stages, overlayed on your camera image.",
              "Present key ideas visually next to your face, to increase engagement and communicate emotions instead of sharing just your screen.",
            ]}
            image={imageWithName("meetcam-bullet-points")}
          />

          <FeatureLeft
            id="virtual-background"
            title="Virtual Background"
            youtubeID="EMf7_zgQsWo"
            description={[
              "Replaces your background with picture or video of your choice showing yourself on in front of it.",
              "People engage more when they see something more interesting than your always-perfect-and-spotless apartment.",
            ]}
            image={imageWithName("meetcam-virtual-background")}
          />

          <FeatureRight
            id="side-notes"
            title="Side Notes"
            youtubeID="gDjYg6eVilI"
            description={[
              "Puts your camera image next to large area where you can take notes for everyone to see.",
              "Be  seen where you take notes for everyone, so you can participate in the discussion better and bring people (literally) to the same page.",
            ]}
            image={imageWithName("meetcam-side-notes")}
          />

          <FeatureLeft
            id="full-screen-notes"
            title="Full Screen Notes"
            youtubeID="jw7KleIacTc"
            description={[
              "Turns your background into a virtual notepad to share with everyone.",
              "You become a true host of the note taking process putting you front-and-center along with them.",
            ]}
            image={imageWithName("meetcam-full-screen-notes")}
          />

          <FeatureRight
            id="spin-wheel"
            title="Spin Wheel"
            youtubeID="nRm2SpvXN4U"
            description={[
              "Puts up an animated spin wheel that draws a random option from provided list.",
              "Allows you to randomly and transparently choose on next person to speak, next action, flip a coin, or name for office, err.. house pet and everyone to share the excitement.",
            ]}
            image={imageWithName("meetcam-spin-wheel")}
          />
          
          <FeatureLeft
            id="weather"
            title="Weather"
            youtubeID="Pq5WwbDzWic"
            description={[
              "Displays your location, local time and weather conditions.",
              "Perfect conversation starter for distributed teams.",
            ]}
            image={imageWithName("meetcam-weather")}
          />
        </div>
      </div>
    </Section>
  )
}
