import React, { useEffect, useState, useCallback } from "react"
import Pattern from "./pattern"
import moment from "moment"

const testimonials = [
  // { name: "Greg W.", text: "Every. Fuckin. Meeting. Pliz. Take my money." },
  { name: "Noemi G.", text: "Super useful" },
  {
    name: "Maciej Ł.",
    text:
      "Interactive, modern and fun. Productivity with agenda, and time is awesome",
  },
]

const Testimonial = (props) => (
  <blockquote className="flex flex-col justify-center h-1/2 min-h-1/3">
    <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900 h-1/3">
      <p>&ldquo;{props.text}&rdquo;</p>
    </div>
    <footer className="mt-8 h-1/3">
      <div className="md:flex md:items-center md:justify-center">
        {/* <div className="md:flex-shrink-0">
          <img
            className="mx-auto h-10 w-10 rounded-full"
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
        </div> */}
        <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center  h-1/3">
          <div className="text-base leading-6 font-medium text-gray-900">
            {props.name}
          </div>

          {/* <svg
            className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M11 0h3L9 20H6l5-20z" />
          </svg> */}

          {/* <div className="text-base leading-6 font-medium text-gray-500">
            CEO, Workcation
          </div> */}
        </div>
      </div>
    </footer>
  </blockquote>
)

export default () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[0])
  const [startedAt] = useState(moment())

  const updateSlides = useCallback(() => {
    const seconds = moment().unix() - startedAt.unix()

    // setCurrentBuiltFor(builtFor[seconds % builtFor.length])
    setCurrentTestimonial(testimonials[seconds % testimonials.length])
  }, [startedAt, setCurrentTestimonial])

  useEffect(() => {
    const timer = setTimeout(() => {
      updateSlides()
    }, 1500)

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  })

  return (
    <div className="my-0 mt-10 min-h-screen h-screen sm:min-h-1/2 flex flex-col justify-center xl:max-h-halfscreen">
      <div className="bg-gray-50 overflow-hidden">
        <div
          className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 flex-col justify-center"
          id="testimonials"
        >
          <Pattern />

          <div className="relative">
            <Testimonial
              name={currentTestimonial.name}
              text={currentTestimonial.text}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
