import React, { useState } from "react"
import Section from "./section"
import Confetti from 'react-dom-confetti'

import db from '../libs/firebase'

const Wrapper = props => <div style={{ position: "relative" }} {...props} />

const ConfettiWrapper = props => (
  <div style={{ position: "absolute", top: 0, right: 0 }} {...props} />
)

const DownloadButton = (props) => (
  <div className="inline-flex rounded-md shadow mt-10 sm:mt-0">
    <a
      href="/#download"
      id="main-cta-download-button"
      className="inline-flex items-center justify-center px-5 py-1 md:py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
      onClick={props.onClick}
      disabled={props.inProgress}
    >
      {props.inProgress ? "Please wait..." : (
        <div>
          <div>
            Download Meet Cam β <br className="block md:hidden" /> for macOS
          </div>
          {/* <div className="text-xs opacity-75 m-0 p-0">
            (beta macOS version)
          </div> */}
        </div>
      )}
    </a>
  </div>
)

const EmailForm = (props) => {
  const [inputValue, setInputValue] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const thanks = () => <div className="text-center" id="signup-thanks">
  <Wrapper>
    <ConfettiWrapper>
      <Confetti active={submitted} />
    </ConfettiWrapper>
  </Wrapper>
  <h3 className="text-4xl mb-4 font-bold text-indigo-500">Thanks, the invite is on it's way! <div role="img" aria-label="love-letter">💌</div></h3>
</div>

  const joinTheList = () => <div>
  <h3 className="text-3xl sm:text-4xl sm:mb-4 font-bold text-indigo-500">Thanks for your interest in Meet Cam!</h3>
  <h4 className="mb-5 mt-5 sm:mt-10 text-xl sm:text-2xl text-gray-900">Join our testers list below and get a fresh build of Meet Cam for macOS for free.</h4>
  <div className="w-full flex flex-col items-center mt-10" id="signup-form">
    <div className="text-left sm:w-1/2">
      <form onSubmit={async (e) => {
        e.preventDefault()
        await props.onSubmit(inputValue)
        setSubmitted(true)
      }}>
        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email</label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
          </div>

          <input id="email" type="email" autoFocus className="form-input block w-full pl-10 sm:text-sm sm:leading-5" placeholder="you@example.com" value={inputValue} onChange={(e) => setInputValue(e.currentTarget.value)} />
        </div>
        <p className="mt-2 text-sm text-gray-500" id="email-description">We will only reach out about Meet Cam, promise.</p>

        <div className="w-full text-right mt-6">
          <span className="inline-flex rounded-md">
            <input type="submit" style={{ visibility: 'hidden' }} />
            <button
              id="main-cta-signup-button"
              type="button" className="inline-flex shadow-sm items-center px-4 py-3 border border-transparent text-s leading-2 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
              onClick={async (e) => {
                await props.onSubmit(inputValue)
                setSubmitted(true)
              }}>
              Get Meet Cam β <br className="block md:hidden" /> for macOS!
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
  </div>

  return (submitted ? thanks() : joinTheList())
}

export default ({ data }) => {
  const [formRevealed, setFormRevealed] = useState(false)
  const [formRevealInProgress, setFormRevealInProgress] = useState(false)

  const submit = async (email) => {
    return db.collection('emails').add({
      email,
      userAgent: navigator.userAgent 
    })
  }

  return <Section id="download" className="min-h-screen h-screen sm:min-h-1/3 sm:h-1/3 xl:max-h-halfscreen">
    <div className="">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8 flex flex-col justify-center min-h-screen h-screen sm:min-h-1/3 sm:h-1/3">
        <div className="text-center">
          {formRevealed ? <EmailForm onSubmit={submit} /> : (
            <div>
              <div>
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  Ready to give your online meetings an extra boost?
                </h2>
              </div>

              <div className="sm:mt-8 flex justify-center">
                <DownloadButton
                  inProgress={formRevealInProgress}
                  onClick={(e) => {
                    e.preventDefault()
                    setFormRevealInProgress(true)

                    setInterval(() => {
                      setFormRevealed(true)
                    }, 500)
                  }
                }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </Section>
}
