import React, { useState } from "react"
import Confetti from 'react-dom-confetti'
import Section from "./section"
import c from "classnames"

import db from '../libs/firebase'

const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}

const Wrapper = props => <div style={{ position: "relative" }} {...props} />

const ConfettiWrapper = props => (
  <div style={{ position: "absolute", top: 0, right: 0 }} {...props} />
)

const SuggestionForm = (props) => {
  const [comment, setComment] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState('')

  return <div className="mt-5">
    <Wrapper>
      <ConfettiWrapper>
        <Confetti active={submitted} config={confettiConfig} />
      </ConfettiWrapper>
    </Wrapper>

    {submitted ? <div className="text-center min-h-1/4 w-full flex flex-col justify-center" id="comment-thanks">
      <h3 className="text-2xl leading-10 font-bold text-indigo-500">🙏🏼 Thank you, all your feedback is <span className="underline">hugely</span> appreciated! 🙇🏼‍♂️</h3>
    </div> : <form onSubmit={async (e) => {
          setSubmitting(true)
          e.preventDefault()
          await props.onSubmit(email, comment)
          setSubmitted(true)
          setSubmitting(false)
        }}
    >
      <div>
        <label htmlFor="comment" className="block text-sm font-medium leading-5 text-gray-700">Comment</label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <textarea id="comment" autoFocus className="form-input block w-full sm:text-sm sm:leading-5" placeholder="your comment goes here..." value={comment} onChange={(e) => setComment(e.currentTarget.value)} />
        </div>
      </div>

      <div className="mt-2">
        <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email</label>
        <div className="flex flex-row w-full justify-between items-center">
          <div className="my-1 relative rounded-md shadow-sm w-2/3">
            <input id="email" className="form-input block w-full sm:text-sm sm:leading-5" placeholder="your@email.here" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
          </div>
          <div className="relative rounded-md w-1/4">
            <button
              id={`faq-suggestion-send`}
              disabled={submitting}
              className="py-3 border border-transparent text-s leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 w-full shadow-sm"
              onClick={async (e) => {
                setSubmitting(true)
                e.preventDefault()
                await props.onSubmit(email, comment)
                setSubmitted(true)
                setSubmitting(false)
              }}
          >
              {submitting ? 'Sending...' : 'Send'}
            </button>
          </div>
        </div>
      </div>
    </form>}
  </div>
}

const FaqItem = (props) => {
  const { question, firstItem, children } = props
  const [open, setOpen] = useState(false)

  return (
    <div
      className={c({ "mt-6 border-t border-gray-200 pt-6 xl:max-h-halfscreen": !firstItem })}
      id="faq"
    >
      <dt className="text-lg leading-7">
        {/* Expand/collapse question button */}
        <button
          id={`faq-${props.id}-${open ? 'close' : 'open'}`}
          className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
          onClick={() => setOpen(!open)}
        >
          <span className="font-medium text-gray-900">{question}</span>
          <span className="ml-6 h-7 flex items-center">
            <svg
              className={c("h-6 w-6 transform", { "-rotate-180": open })}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </button>
      </dt>
      {open && (
        <dd className="mt-2">
          <div className="text-base leading-6 text-gray-500 w-full">{children}</div>
        </dd>
      )}
    </div>
  )
}

export default () => {
  const [suggestionFormOpened, setSuggestionFormOpened] = useState(false)

  const submit = async (email, text) => {
    return db.collection('comments').add({
      text,
      email
    })
  }

  return <Section>
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Frequently Asked Questions
          </h2>
          <div className="mt-6 border-t-2 border-gray-200 pt-6">
            <dl>
              <FaqItem
                id="platforms"
                question="Does it work on Zoom? Hangouts? Whereby? X? Y? Z?"
                firstItem={true}
              >
                Yes. It works with every app that uses any camera to work, unless the app developers purposely disabled virtual camera use.
              </FaqItem>

              <FaqItem
                id="everyone-installed"
                question="Does everyone on the call need to have something installed?"
              >
                No, everyone who is able to otherwise see your video (i.e. your face) on the call, will also see elements displayed by Meet Cam. No downloads, installs or signups of any kind for them.
              </FaqItem>

              <FaqItem
                id="feature-x"
                question="Can you make it do X?"
              >
                We are hard at work on adding new features, so
                &nbsp;
                <span
                  onClick={() => setSuggestionFormOpened(true)}
                  className="underline cursor-pointer"
                >
                  let us know what you need
                </span>
                &nbsp;
                -- chances are we will make it available soon enough!

                {suggestionFormOpened && <SuggestionForm onSubmit={submit} />}
              </FaqItem>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </Section>
}