import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MainHero from "../components/mainHero"
// import BuiltFor from "../components/builtFor"
import Features from "../components/features"
import MainCTA from "../components/mainCTA"
import Testimonials from "../components/testimonials"
import Team from "../components/team"
import Faq from "../components/faq"
import Footer from "../components/footer"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Meet Cam: make your online meetings productive and fun!"
      description="Meet Cam adds useful (like timers and agenda) and fun widgets (emojis and gifs!) to your camera feed for everyone to see next to you on the call."
    />
    <MainHero />
    {/* <BuiltFor /> */}
    <Features />
    <MainCTA />
    <Faq />
    <Testimonials />
    <Team />
    <Footer />
  </Layout>
)

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default IndexPage
