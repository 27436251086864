import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Section from "./section"

const TeamMember = (props) => (
  <li>
    <div className="space-y-4 flex flex-col justify-start">
      <div className="mb-2/3">
        <Img
          className="object-cover h-56 w-56 shadow-lg rounded-lg"
          fixed={props.image}
          alt={props.name}
        />
      </div>
      <div className="text-lg leading-6 font-medium space-y-1">
        <h4>{props.name}</h4>
        {/* <p className="text-indigo-600">Senior Front-end Developer</p> */}
      </div>
      <div className="text-lg leading-7">
        <p className="text-gray-500">{props.description}</p>
      </div>

      <ul className="flex space-x-5">
        <li>
          <a
            href={props.twitterUrl}
            className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
          >
            <span className="sr-only">Twitter</span>
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </li>
        <li>
          <a
            href={props.linkedInUrl}
            className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
          >
            <span className="sr-only">LinkedIn</span>
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </li>
)

export default () => {
  const data = useStaticQuery(graphql`
    query {
      daniel: file(relativePath: { eq: "images/daniel.jpg" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      zbigniew: file(relativePath: { eq: "images/zbigniew.jpg" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Section className="xl:max-h-halfsceen">
      <div className="bg-white" id="team">
        <div className="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">
                Our Team
              </h2>
              <p className="text-xl leading-7 text-gray-500">
                It's just the two of us!
              </p>
            </div>
            <div className="lg:col-span-2">
              <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                <TeamMember
                  name="Daniel Owsiański"
                  description="An obsessive-compulsive innovator. I express myself through Making not talking."
                  image={data.daniel.childImageSharp.fixed}
                  twitterUrl="https://twitter.com/dannyow"
                  linkedInUrl="https://linkedin.com/in/danielowsianski"
                />
                <TeamMember
                  name="Zbigniew Sobiecki"
                  description="Jack of all trades, master of none. Enjoys working on the boring stuff so brilliant people can innovate."
                  image={data.zbigniew.childImageSharp.fixed}
                  twitterUrl="https://twitter.com/zbigniew"
                  linkedInUrl="https://linkedin.com/in/zbigniewsobiecki"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
